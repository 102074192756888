.nav-slider{
    list-style: none;
    a{
     text-decoration: none;
     color: unset;
    }
}
.k-img-slider{
    display: flex;
    justify-content: center;
    display: none;
    figure{
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            height: 52px;
            width: 52px;
            border-radius: 50%;
        }
        figcaption{
            text-align: center;
            font-size: 11px;
        }
    }
    
    @media screen and (max-width:992px) {
        overflow-x: scroll;  
        // display: block;
        display: flex;  
    }
}